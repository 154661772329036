<template>
  <div>
    <!-- Title page -->
    <div class="mx-auto mb-2">
     
      <b-modal id="FilterStatistiqueModal"
       :title="name"
       @hidden="resetModal"
       ref="FilterStatistiqueModal"
        hide-footer
         centered hide-header-close
         >
         <b-overlay
        :show="showLoading"
        no-wrap
         />         
       <validation-observer ref="formextraction"> 
          <b-form class="p-2">
        

             <b-form-group label="Date Debut*" label-for="date-debut"  v-if="name != 'COMMISSIONS_SL'"> 
              <validation-provider  v-slot="{ errors }" name="date debut" rules="required"> 
                <flat-pickr id="date-debut" v-model="date_debut" 
                  placeholder="Date Debut" :config="configDate" 
                  :class="errors.length > 0 ? 'is-invalid' : ''"  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}
                </b-form-invalid-feedback> 
              </validation-provider>  
            </b-form-group>

            <b-form-group label="Date Fin*" label-for="date-fin"  v-if="name != 'COMMISSIONS_SL'"> 
              <validation-provider v-slot="{ errors }" name="date fin" rules='required'> 
                <flat-pickr id="date fin" v-model="date_fin"
                  placeholder="Date Fin" :config="configDate" 
                  :class="errors.length > 0 ? 'is-invalid' : ''"  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}
                </b-form-invalid-feedback> 
              </validation-provider>  
            </b-form-group>

            <b-form-group label="Mois*" label-for="specified_month" v-if="name == 'COMMISSIONS_SL'"> 
                <validation-provider  v-slot="{ errors }" name="Mois" rules="required"> 
                  <v-select v-model="specified_month" :close-on-select="false" autocomplete :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :getOptionKey="month => month.value + month.text" :options="months" :reduce="month => month.value" label="text" placeholder="mois du bordereau">
                    <template #option="{ text }">
                      <span class="ml-50"> {{ text }}</span>
                    </template>
                    <div slot="no-options">Aucun résultats.</div>
                  </v-select>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}
                  </b-form-invalid-feedback> 
                </validation-provider>  
              </b-form-group>

              <b-form-group label="Année*" label-for="specified_year"  v-if="name == 'COMMISSIONS_SL'">
              <validation-provider #default="{ errors }" name="Année" rules="required">
                <b-form-input id="specified_year" v-model="specified_year"  :state="errors.length > 0 ? false : null" placeholder="année du bordereau" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <div class="d-flex mt-2" style="margin: auto; width: 50%; padding: 10px;">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" @click="submitExtraction">
                Oui
              </b-button>
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-danger" class="mr-2" @click="resetModal">
                Non
              </b-button>
            </div>

          </b-form>
         </validation-observer> 
      </b-modal>


      

        
      <b-row class="justify-content-between align-items-center">
        <b-col md="4">
          <h1 class="text-primary font-weight-bolder text-uppercase ml-1 my-0">EXTRACTION</h1>
        </b-col>
        <b-col>
         
        </b-col>
      </b-row>
    </div>

    <b-overlay :show="showLoading" no-wrap />
    <vue-good-table
      class="custom-table-bottom"
      v-if="can('List modeles')"
      :columns="exportColumns"
      :rows="rowsMetier"
      :rtl="direction"
      style-class="vgt-table condensed"
      :pagination-options="{
        enabled: true,
        mode: 'records',
        perPage: pageLength,
        rowsPerPageLabel: '',
        dropdownAllowAll: false,
        perPageDropdownEnabled: false,
        nextLabel: 'Suivant',
        prevLabel: 'Précédent',
        ofLabel: 'de',
        infoFn: params => ``
      }"
    >
      <template slot="table-row" slot-scope="props">
        <!-- Column: Action -->
        <span v-if="props.column.field === 'actions'">
          <span class="cursor-pointer">
             <feather-icon icon="DownloadCloudIcon" @click="getBase64Document(props.row.file)" size="20" class="mr-50" />
          </span>
        </span>
        <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
      </template>

      <!-- pop up  -->
   
        
      
        
              
      
      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <b-row class="mt-2 align-items-center">
              <b-col md="6" lg="5" xxl="3">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap ">
                    Affichage 1 à
                    
                  </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['5','10','15']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                  />
                  <span class="text-nowrap"> de {{ props.total }} entrées </span>
                </div>
              </b-col>
          </b-row>
        <b-row class="mt-2 align-items-center">
          <b-col>
            <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" @input="value => props.pageChanged({ currentPage: value })">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="25" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </template>
      <div slot="emptystate" class="text-center align-middle">
        <span>Aucune donn&eacute;e disponible dans le tableau</span>
      </div>
    </vue-good-table>
  </div>
</template>

<script>
import { BPagination,BFormInvalidFeedback,BFormInput, VBToggle, BRow,BModal, BCol, BDropdown, BDropdownItem, BButton, BCard, BCardText, BCardHeader, BMedia, BImg, BOverlay, BFormSelect, BForm,BFormGroup } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'
import { VueGoodTable } from 'vue-good-table'
import flatPickr from 'vue-flatpickr-component'
import { required } from '@validations'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { extend } from 'vee-validate';
import vSelect from "vue-select"

extend('dateDebutValidation', {
  validate(value, { otherValue }) {
    return value <= otherValue;
  },
  params: ['otherValue'],
  message: 'La date de début doit être inférieure à la date de fin.',
});


export default {
  components: {
    VBToggle,
    vSelect,
    BFormInput,
    BDropdown,
    BDropdownItem,
    BRow,
    BModal,
    BCol,
    BButton,
    BCard,
    BCardText,
    BCardHeader,
    BMedia,
    BImg,
    BOverlay,
    VueGoodTable,
    BPagination,
    BFormSelect,
    BForm,
    ToastificationContentVue,
    flatPickr,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple
  },
  data() {
    return {
      startDate : null,
      endDate : null,
      showLoading:false,
      name:null,
      date_debut: null,
      date_fin: null,
      specified_month:null,
      specified_year: new Date().getFullYear(),
      months : [
          { text: 'Janvier', value: 1 },
          { text: 'Février', value: 2 },
          { text: 'Mars', value: 3 },
          { text: 'Avril', value: 4 },
          { text: 'Mai', value: 5 },
          { text: 'Juin', value: 6 },
          { text: 'Juillet', value: 7 },
          { text: 'Août', value: 8 },
          { text: 'Septembre', value: 9 },
          { text: 'Octobre', value: 10 },
          { text: 'Novembre', value: 11 },
          { text: 'Décembre', value: 12 }
        ],
      configDate: {
          dateFormat: 'd/m/Y',
          altFormat: 'd/m/Y',
          altInput: true,      
          allowInput: true,
          onKeyDown: (selectedDates, dateStr, instance, event) => {
            if (event.keyCode != 8) {
              var ele = document.activeElement;
              var val = ele.value;

              if ((val.length == 2 || val.length == 5) && ele.getAttribute('id')) {
                val += "/";
              }
              ele.value = val;
              ele.dispatchEvent(new Event("input"));
            }
          },
      },
      selected_date_effet: null,
      pageLength: 5,
      dir: false,
      renderComponent: true,
      rowsMetier: [{
          titre:'Statistiques des adhesions BubbleIn',
          objet:'Statistiques des courtiers qui ont passé par le parcours d\'inscription de BubbleIn',
          file: 'ADHESION_STATISTICS'
      },
      {
          titre:'Codes courtiers compagnies',
          objet:'Liste des courtiers actifs qui sont codés chez des compagnies',
          file: 'PRODUIT_STATISTICS'
      },
      {
        titre:'Statistiques sur la marketplace',
        objet:'Statistiques devis/contrats realisés sur la marketplace',
        file: 'DEVIS_STATISTICS'
      },
      {
          titre:'Liste des courtiers BubbleIn',
          objet:'Liste des courtiers actifs/inactifs de l’application',
          file: 'COURTIERS_STATISTICS'
      },
      {
          titre:'Liste des courtiers résiliés BubbleIn',
          objet:'Liste des courtiers résiliés de l’application',
          file: 'COURTIERS_RESILIE'
      },
      {
          titre:'Statistiques sur la tarification',
          objet:'Statistiques devis/contrats/cabinet realisés sur la tarification',
          file: 'TARIFICATION_STATISTICS'
      },
      {
          titre:'Des commissions courtier-SWL',
          objet:'Les commissions que BBI doit reverser au courtier par le partenaire SwissLife,',
          file: 'COMMISSIONS_SL'
      },
      // {
      //     titre:'Sinistres',
      //     objet:'Extraction des sinistres',
      //     file: 'SINISTRES'
      // }
      {
        titre:'Devis éditée',
        objet:'Devis éditée',
        file: 'DEVIS_EDITEE'
      },
      {
        titre:'Affichage MKP',
        objet:'Affichage MKP',
        file: 'DEVIS_MKP'
      },
      {
        titre:'contrat_afn',
        objet:'contrat_afn',
        file: 'contrat_afn'
      },
      {
        titre:'Affichage TR',
        objet:'Affichage TR',
        file: 'Affichage_TR'
      },
      {
        titre: 'STOCK_COURTIERS',
        objet: 'STOCK_COURTIERS',
        file: 'STOCK_COURTIERS'
      },
      {
        titre: 'Code maitre',
        objet: 'BSB (code maitre) - extraction journalier',
        file: 'REPORTING_BUBBLEIN'
      },
    ],
      exportColumns: [
        { label: 'Titre', field: 'titre', sortable: false, thClass: 'text-center align-middle bg-primary text-white unbreaking', tdClass: 'text-center m-0 p-2 align-middle' },
        { label: 'Description', field: 'objet', sortable: false, thClass: 'text-center align-middle bg-primary text-white unbreaking', tdClass: 'text-center m-0 p-2 align-middle' },
        { label: 'Actions', field: 'actions', sortable: false, thClass: 'text-center align-middle bg-primary text-white unbreaking', tdClass: 'text-center m-0 p-2 align-middle' }
      ]
    }
  },


  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        this.dir = true
        return this.dir
      }
      this.dir = false
      return this.dir
    }
  },
  
  methods: {

    resetModal() {
      
      this.date_debut =  null;
      this.date_fin = null;
      this.specified_month = null;
      this.specified_year = new Date().getFullYear();
      this.$refs['FilterStatistiqueModal'].hide()

    }, 

    forceRerender() {
      this.renderComponent = false
      this.$nextTick(() => {
        this.renderComponent = true
      })
    },
    messageToast(text, title, variant) {
      this.$toast({
        component: ToastificationContentVue,
        props: {
          title,
          icon: 'BellIcon',
          text,
          variant
        }
      })
    },


  submitExtraction(){
    this.$refs.formextraction.validate()
    .then(success => {
      if (success) {  
        this.showLoading = true
        let date_debut = new Date(moment(this.date_debut, "DD/MM/YYYY").format("YYYY-MM-DD"));
        let date_fin = new Date(moment(this.date_fin, "DD/MM/YYYY").format("YYYY-MM-DD"));

        const isDateValid = date_debut <= date_fin;
        console.log(isDateValid)
        if(isDateValid){
          const formData = new FormData()       
          formData.append('name', this.name)
          formData.append('date_debut',this.date_debut)
          formData.append('date_fin', this.date_fin)
          formData.append('specified_month', this.specified_month)
          formData.append('specified_year', this.specified_year)
          const config = { headers: { 'Content-Type': 'multipart/form-data' }, responseType: "blob" }
          this.$http
          .post('/document/exportStatistics', formData, config)
          .then(res => {
            console.log(res.data)
            this.showLoading = false
            this.$refs['FilterStatistiqueModal'].hide() 
            this.downloadDocument(res.data, this.name)
          
          })
          .catch(err => {
            console.error(err)
            this.showLoading = false
          })
          this.resetModal()
        }
        else {
          console.log('111')
          this.$refs.formextraction.setErrors({ 'date debut': ['La date de début doit être inférieure à la date de fin.'] });
          this.showLoading = false
        }
      }
    }) 
  },
    getBase64Document(name) {

       switch (name) {
          case 'DEVIS_STATISTICS':
          case 'TARIFICATION_STATISTICS':
          case 'COURTIERS_RESILIE':
          case 'COURTIERS_STATISTICS':
          case 'PRODUIT_STATISTICS':  
          case 'ADHESION_STATISTICS':
          case 'COMMISSIONS_SL':
          case 'SINISTRES':
          case 'DEVIS_EDITEE':
          case 'contrat_afn':
          case 'Affichage_TR':
          case 'STOCK_COURTIERS':
          case 'DEVIS_MKP':
          // case 'CODE_MAITRE':
              this.name = name;
              //-------
              this.$refs['FilterStatistiqueModal'].show();
              return 0;

            default:
              // Handle other cases if needed
              break;
          }
              
      // this.showLoading = true
      this.$http
        .post(`/document/exportStatistics`, {
          name: name,
        }, {
          responseType: "blob"  
        })
        .then(res => {
          this.downloadDocument(res.data, name)
          
        })
        .catch(err=>{
          
          this.$refs['FilterStatistiqueModal'].hide() 
          this.messageToast('une erreur a été survenue', 'Erreur', 'warning')
        })
    },
    downloadDocument(blob, name) {
      if (blob) {
        var fileURL = window.URL.createObjectURL(new Blob([blob]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', `${name}_${moment().format("YYYYMMDDHHmm")}.xlsx`);
        document.body.appendChild(fileLink);
        fileLink.click();
        this.messageToast('Le document a été téléchargé avec succès.', 'Succès', 'success')
      } else {
        this.messageToast('Aucun document trouvé à télécharger, veuillez essayer de le télécharger après.', 'Erreur', 'warning')
      }
    },
  }
}
</script>

<style lang="scss">


@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
div.custom-table-bottom >div >.vgt-responsive{
    min-height: 0 !important;
}
</style>